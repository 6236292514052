<template>
  <div>
    <b-card title="Operações">
      <!-- search input -->
      <b-row
        align-h="between"
        class="mb-2"
      >
        <b-col cols="1">
          <b-form-select
            v-model="perPage"
            :options="[10, 15, 20]"
            size="sm"
          />
        </b-col>

        <b-col
          class="d-flex justify-content-end"
          cols="8"
        >
          <b-form-input
            v-model="filter"
            type="search"
            size="sm"
            placeholder="Pesquisar"
          />
        </b-col>
      </b-row>
      <b-table
        hover
        bordered
        selectable
        responsive
        select-mode="single"
        :per-page="perPage"
        :current-page="currentPage"
        :fields="columns"
        :items="rows"
        :filter="filter"
        @filtered="onFiltered"
        @row-clicked="onRowClick"
      />
      <b-pagination
        v-model="currentPage"
        :per-page="perPage"
        :total-rows="totalRows"
        align="center"
        size="sm"
      />
    </b-card>

    <b-modal
      id="modal"
      hide-footer
      centered
      size="lg"
      title="Detalhes da Operação"
    >
      <InfoCard
        :id-op="id"
      />
    </b-modal>

  </div>
</template>

<script>
import {
  BTable,
  BPagination,
  BFormInput,
  BFormSelect,
  BCard,
  BModal,
  BCol,
  BRow,
} from 'bootstrap-vue'
import store from '@/store/index'
import InfoCard from '@/views/backoffice/operacao/cards/OperacoesInfo.vue'

export default {
  components: {
    InfoCard,
    BTable,
    BPagination,
    BFormInput,
    BFormSelect,
    BCard,
    BCol,
    BRow,
    BModal,
  },
  props: {
    operacoes: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      id: null,
      detalhesOperacao: {
        numero: null,
        cliente: null,
        cpfCnpj: null,
        vencimento: null,
        valor: null,
      },
      filter: '',
      perPage: 10,
      totalRows: 1,
      currentPage: 1,
      dir: false,
      columns: [
        { label: 'Operação', key: 'numero_operacao' },
        { label: 'Cliente', key: 'cliente_nome', class: 'text-left' },
        { label: 'Vencimento', key: 'data_vencimento' },
        { label: 'Valor', key: 'valor_nominal', class: 'text-right' },
      ],
      rows: [],
      searchTerm: '',
      status: [{
        1: 'Current',
        2: 'Professional',
        3: 'Rejected',
        4: 'Resigned',
        5: 'Applied',
      },
      {
        1: 'light-primary',
        2: 'light-success',
        3: 'light-danger',
        4: 'light-warning',
        5: 'light-info',
      }],
    }
  },
  computed: {
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        Current      : 'light-primary',
        Professional : 'light-success',
        Rejected     : 'light-danger',
        Resigned     : 'light-warning',
        Applied      : 'light-info',
        /* eslint-enable key-spacing */
      }

      return status => statusColor[status]
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },
  created() {
    // this.rows = this.operacoes
    this.operacoes.map(dt => {
      this.rows.push({
        id: dt.id,
        numero_operacao: dt.numero_operacao,
        cliente_nome: dt.cliente_nome,
        data_vencimento: dt.data_vencimento,
        valor_nominal: dt.valor_nominal,
      })
    })
    this.totalRows = this.operacoes.length
  },
  methods: {
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    onRowClick(item) {
      this.id = null
      this.id = item.id
      this.$bvModal.show('modal')
    },
  },
}
</script>

<style lang="scss" >
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
