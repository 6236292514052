<template>
  <div>
    <div>
      <b-card>
        <b-row>

          <!-- User Info: Left col -->
          <b-col
            cols="21"
            xl="6"
            class="d-flex justify-content-between flex-column"
          >
            <!-- User Avatar & Action Buttons -->
            <div class="d-flex justify-content-start">
              <div class="d-flex flex-column ml-1">
                <div class="mb-1">
                  <h4 class="mb-0">
                    Remessa: {{ remessa.numero_remessa }}
                  </h4>
                  <span class="card-text">{{ remessa.numero }}</span>
                </div>
                <div class="d-flex flex-wrap">
                  <b-button
                    v-if="showDeletar"
                    variant="outline-danger"
                    @click="deletRemessa(remessa.id, remessa.numero_remessa)"
                  >
                    Deletar
                  </b-button>
                  <div class="d-flex align-items-center ml-4">
                    <b-button
                      title="Se a remessa importada não aparecer os dados, clique aqui para forçar o processamento novamente"
                      variant="outline-warning"
                      @click="reprocessRemessa(remessa.id, remessa.numero_remessa)"
                    >
                      Reprocessar Remessa
                    </b-button>
                  </div>
                  <div class="d-flex align-items-center ml-4">
                    <b-button
                      variant="outline-primary"
                      @click="baixarRemessa(remessa.id, remessa.numero_remessa)"
                    >
                      Baixar Remessa
                    </b-button>
                  </div>
                </div>
              </div>
            </div>

            <!-- User Stats -->
            <b-row>
              <b-col>
                <div class="d-flex align-items-center ml-1 mt-2">
                  <b-row>
                    <b-col
                      v-for="(item, index) in items"
                      :key="item.icon"
                      xl="3"
                      sm="6"
                      cols="auto"
                      :class="item.customClass"
                      @click="itemClick(index)"
                    >
                      <div class="d-flex align-items-center mr-2">
                        <b-avatar
                          :variant="item.color"
                          rounded
                        >
                          <feather-icon
                            :icon="item.icon"
                          />
                        </b-avatar>
                        <div class="ml-1">
                          <h5 class="mb-0">
                            {{ item.title }}
                          </h5>
                          <smal>{{ item.subtitle }}</smal>
                        </div>
                      </div>
                    </b-col>

                  </b-row>
                </div>
              </b-col>
            </b-row>
          </b-col>

          <!-- Right Col: Table -->
          <b-col
            cols="12"
            xl="6"
          >
            <table class="mt-2 mt-xl-0 w-100">
              <tr>
                <th class="pb-50">
                  <feather-icon
                    icon="AtSignIcon"
                    class="mr-75"
                  />
                  <span class="font-weight-bold">Usuário</span>
                </th>
                <td class="pb-50">
                  {{ remessa.usuario_nome }}
                </td>
              </tr>
              <tr>
                <th class="pb-50">
                  <feather-icon
                    icon="UserIcon"
                    class="mr-75"
                  />
                  <span class="font-weight-bold">Credor</span>
                </th>
                <td class="pb-50 text-capitalize">
                  {{ remessa.credor_nome }}
                </td>
              </tr>
              <tr>
                <th class="pb-50">
                  <feather-icon
                    icon="WatchIcon"
                    class="mr-75"
                  />
                  <span class="font-weight-bold">Data Remessa</span>
                </th>
                <td class="pb-50 text-capitalize">
                  {{ remessa.data_remessa }}
                </td>
              </tr>
              <tr>
                <th class="pb-50">
                  <feather-icon
                    icon="WatchIcon"
                    class="mr-75"
                  />
                  <span class="font-weight-bold">Data Entrada</span>
                </th>
                <td class="pb-50">
                  {{ remessa.data_entrada }}
                </td>
              </tr>
              <tr>
                <th class="pb-50">
                  <feather-icon
                    icon="CalendarIcon"
                    class="mr-75"
                  />
                  <span class="font-weight-bold">Vencimentos Títulos</span>
                </th>
                <td class="pb-50">
                  {{ menorDataTitulo }} à {{ maiorDataTitulo }}
                </td>
              </tr>
            </table>
          </b-col>
        </b-row>
      </b-card>
    </div>
  </div>
</template>

<script>
import {
  BCard, BButton, BAvatar, BRow, BCol,
} from 'bootstrap-vue'
import axios from '../../../../../axios-auth'

export default {
  components: {
    BCard, BButton, BRow, BCol, BAvatar,
  },
  props: {
    selectCard: {
      type: Function,
      required: false,
      default: () => {},
    },
    operacoesNaoImportadas: {
      type: Array,
      required: true,
    },
    remessa: {
      type: Object,
      required: true,
    },
    clientes: {
      type: Number,
      required: true,
    },
    total: {
      type: Number,
      required: true,
    },
    menortitulo: {
      type: String,
      required: true,
    },
    maiortitulo: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      items: [
        {
          color: 'light-primary',
          customClass: 'mt-1',
          icon: 'UserIcon',
          subtitle: 'Clientes',
          title: this.clientes,
        },
        {
          color: 'light-warning',
          customClass: 'mt-1 cursor-pointer',
          icon: 'TrendingUpIcon',
          subtitle: 'Operações',
          title: this.remessa.operacaoTotal,
        },
        {
          color: 'light-success',
          customClass: 'mt-1',
          icon: 'UserPlusIcon',
          subtitle: 'Clientes novos',
          title: this.remessa.clientesNovos,
        },
        {
          color: 'light-success',
          customClass: 'mt-1',
          icon: 'DollarSignIcon',
          subtitle: 'Total',
          title: this.valorBr(this.total, true),
        },
        {
          color: 'light-danger',
          customClass: 'mt-1 cursor-pointer',
          icon: 'XOctagonIcon',
          subtitle: 'Não importadas',
          title: this.operacoesNaoImportadas.length,
        },
      ],
      menorDataTitulo: this.menortitulo,
      maiorDataTitulo: this.maiortitulo,
      showDeletar: false,
    }
  },
  methods: {
    totalsClients() {
      this.items[5].title = 12
    },
    async itemClick(data) {
      if (data === 0) {
        await this.selectCard(0)
      } else if (data === 1) {
        await this.selectCard(1)
      } else if (data === 2) {
        await this.selectCard(2)
      } else if (data === 3) {
        await this.selectCard(3)
      } else if (data === 4) {
        await this.selectCard(4)
      } else if (data === 5) {
        await this.selectCard(5)
      }
    },
    deletRemessa(id, remessa) {
      this.$swal({
        title: 'Tem certeza?',
        text: `Deseja deletar a remessa ${remessa}?`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sim, quero deletar!',
        cancelButtonText: 'Cancelar',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          axios.delete(`api/v1/remessas/delete/${id}`, {
            headers: {
              Authorization: 'Bearer '.concat(localStorage.getItem('token')),
              accept: 'application/json',
            },
          })
            .then(res => {
              if (res.data) {
                this.$router.push({ name: 'remessas' })
                this.$swal({
                  icon: 'success',
                  title: 'Deletado!',
                  text: 'Remessa deletada com sucesso!',
                  timer: 1500,
                  showConfirmButton: false,
                  customClass: {
                    confirmButton: 'btn btn-success',
                  },
                })
              }
            })
        }
      })
    },
    reprocessRemessa(id, remessa) {
      this.$swal({
        title: 'Tem certeza?',
        text: `Deseja reprocessar a remessa ${remessa}?`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sim, quero reprocessar!',
        cancelButtonText: 'Cancelar',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          axios.get(`api/v1/remessas/reprocessar/${id}`, {
            headers: {
              Authorization: 'Bearer '.concat(localStorage.getItem('token')),
              accept: 'application/json',
            },
          })
            .then(res => {
              if (res.data.dados.result) {
                // this.$router.push({ name: 'remessas', params: { id } })
                this.$swal({
                  icon: 'success',
                  title: 'Reprocessado!',
                  text: 'Remessa reprocessada com sucesso!',
                  timer: 1500,
                  showConfirmButton: false,
                  customClass: {
                    confirmButton: 'btn btn-success',
                  },
                })
              }
            })
        }
      })
    },
    baixarRemessa(id) {
      axios.get(`api/v1/remessas/download/${id}`, {
        headers: {
          Authorization: 'Bearer '.concat(localStorage.getItem('token')),
          accept: 'application/json',
        },
        responseType: 'blob',
      }).then(res => {
        const FILE = window.URL.createObjectURL(new Blob([res.data]))
        const docUrl = document.createElement('a')
        docUrl.href = FILE
        docUrl.setAttribute('download', `${this.remessa.numero_remessa}.xlsx`)
        document.body.appendChild(docUrl)
        docUrl.click()
      })
        .catch(() => {
          this.$swal({
            title: 'ERRO',
            icon: 'error',
            text: 'Ocorreu um erro ao baixar o arquivo da remessa!',
            customClass: {
              confirmButtonVariant: 'btn btn-primary',
            },
          })
        })
    },
  },
}
</script>

<style>

</style>
