<template>
  <div>
    <div v-if="permissions['remessa.credor'].permissao">
      <b-button
        id="toggle-btn"
        class="mb-1"
        variant="primary"
        @click="$router.go(-1)"
      >
        <feather-icon
          icon="ArrowLeftIcon"
          class="mr-50"
        />
        <span class="align-middle">Voltar</span>
      </b-button>

      <RemessaInfo
        v-if="load"
        :select-card="loadCard"
        :remessa="remessa"
        :operacoes-nao-importadas="operacoes_nao_importadas"
        :clientes="numeroC"
        :total="total"
        :clientes-novos="clientesNovos"
        :operacoes-novas="operacoesNovas"
        :operacoes="operacoes"
        :maiortitulo="maiorDataTitulo"
        :menortitulo="menorDataTitulo"
      />

      <RemessaOperacoes
        v-if="card === 1"
        :operacoes="operacoes"
        :operacoes-nao-importadas="operacoes_nao_importadas"
      />

      <OperacoesNaoImportadas
        v-if="card === 4"
        :operacoes-nao-importadas="operacoes_nao_importadas"
      />
    </div>
    <div v-else>
      <not-authorized />
    </div>
  </div>

</template>

<script>
import { BButton } from 'bootstrap-vue'
import dayjs from 'dayjs'
import RemessaInfo from '@/views/backoffice/remessa/cards/RemessaInfo.vue'
import RemessaOperacoes from '@/views/backoffice/remessa/cards/RemessaOperacoes.vue'
import OperacoesNaoImportadas from '@/views/backoffice/remessa/cards/OperacoesNaoImportadas.vue'
import notAuthorized from '@/views/misc/notAuthorized.vue'
import axios from '../../../../axios-auth'

export default {
  components: {
    OperacoesNaoImportadas,
    RemessaOperacoes,
    RemessaInfo,
    BButton,
    notAuthorized,
  },
  data() {
    return {
      loadOperacoes: false,
      remessa: {},
      operacoes_nao_importadas: {},
      operacoes: [],
      nClientes: [],
      clientesNovos: [],
      operacoesNovas: [],
      numeroC: 0,
      valorOperacoes: [],
      total: 0,
      permissions: null,

      load: false,
      card: '',
      titulosVencimentos: [],
      menorDataTitulo: '',
      maiorDataTitulo: '',
    }
  },
  async created() {
    this.load = false

    this.permissions = JSON.parse(localStorage.permissoes).remessa

    await axios.get(`api/v1/remessas/edit/${this.$route.params.id}`, {
      headers: {
        Authorization: 'Bearer '.concat(localStorage.getItem('token')),
        accept: 'application/json',
      },
    })
      .then(res => {
        this.operacoes_nao_importadas = res.data.dados.operacoes_nao_importadas
        this.remessa.usuario_nome = res.data.dados.usuario_cadastrou
        this.remessa.id = res.data.dados.remessa.id
        this.remessa.credor_nome = res.data.dados.credor[0].nome
        this.remessa.numero_remessa = res.data.dados.remessa.numero_remessa
        this.remessa.data_remessa = this.formatStr(this.dataHora(res.data.dados.remessa.data_remessa))
        this.remessa.data_entrada = this.converterData(res.data.dados.remessa.data_entrada)
        this.remessa.operacaoTotal = res.data.dados.operacoes.length
        this.remessa.clientesNovos = res.data.dados.remessa.clientes_novos
        this.remessa.operacoesNovas = res.data.dados.remessa.operacoes_novas
        const dadosOperacoes = res.data.dados.operacoes
        const Operacoes = []
        let valorTotal = 0
        // eslint-disable-next-line guard-for-in,no-restricted-syntax
        for (const prop in dadosOperacoes) {
          valorTotal = parseFloat(valorTotal) + parseFloat(valorTotal)
          Operacoes.push({
            id: dadosOperacoes[prop].id,
            numero_operacao: dadosOperacoes[prop].numero_operacao,
            cliente_id: dadosOperacoes[prop].cliente_id,
            cliente_nome: dadosOperacoes[prop].cliente.nome,
            cliente_cpf_cnpj: dadosOperacoes[prop].cliente.cpf_cnpj,
            data_vencimento: this.dataHora(dadosOperacoes[prop].data_vencimento, true),
            valor_nominal: this.valorBr(dadosOperacoes[prop].valor_nominal, true),
          })
          this.nClientes.push(dadosOperacoes[prop].cliente_id)
          this.valorOperacoes.push(dadosOperacoes[prop].valor_nominal)
          this.titulosVencimentos.push(dadosOperacoes[prop].data_vencimento)
        }
        this.remessa.valorTotal = valorTotal
        this.operacoes = Operacoes
        this.loadOperacoes = true
        this.load = true
        this.numeroClientes()
        this.totalRemessa()
        this.verificarDataTitulosVencimento()
      })
  },
  methods: {
    loadCard(data) {
      if (this.card === data) {
        this.card = null
      }
      this.card = data
    },
    formatStr(e) {
      return e.slice(0, 10)
    },

    totalRemessa() {
      const dados = this.valorOperacoes
      this.total = dados.reduce((total, i) => total + i, 0)
    },

    numeroClientes() {
      const num = this.nClientes.filter((este, i) => this.nClientes.indexOf(este) === i)
      this.numeroC = num.length
    },
    dataHora(dados, op = false) {
      const a = dados
      const b = a.split('T')
      const c = b[0].split('-')
      const d = b[1].split('.')
      const e = d[0].split(':')
      const dataBr = `${c[2]}/${c[1]}/${c[0]}`
      const horaBr = `${e[0]}:${c[1]}`
      const dataHora = `${dataBr} ${horaBr}`
      if (op) {
        return dataBr
      }
      return dataHora
    },
    valorBr(dados, op = false) {
      const valorRS = dados.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })
      const valor = dados.toLocaleString('pt-br', { minimumFractionDigits: 2 })
      if (op) {
        return valorRS
      }
      return valor
    },
    verificarDataTitulosVencimento() {
      const dataMilisegundos = []
      this.titulosVencimentos.map(date => {
        dataMilisegundos.push(Date.parse(date))
      })

      const dataMaiorTituloMilisegundos = dataMilisegundos.reduce((a, b) => Math.max(a, b))
      const dataMenorTituloMilisegundos = dataMilisegundos.reduce((a, b) => Math.min(a, b))

      const dataMaiorTitulo = new Date(dataMaiorTituloMilisegundos).toLocaleString().substr(0, 10)
      const dataMenorTitulo = new Date(dataMenorTituloMilisegundos).toLocaleString().substr(0, 10)

      this.maiorDataTitulo = dataMaiorTitulo
      this.menorDataTitulo = dataMenorTitulo
    },
    converterData(data) {
      const date = new Date(data)
      // dayjs reconhece o fuso local e já converte o parametro para este
      const dataJs = dayjs(date, 'YYYY/MM/DD HH:mm:ss')
      const dataFormatada = dataJs.format('DD/MM/YYYY HH:mm:ss')
      return dataFormatada
    },
  },
}
</script>

<style>

</style>
