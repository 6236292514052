<template>
  <div>
    <b-card title="Operações não importadas">
      <b-row
        align-h="between"
        class="mb-2"
      >
        <b-col cols="1">
          <b-form-select
            v-model="perPage"
            :options="[10, 15, 20]"
            size="sm"
          />
        </b-col>
        <b-col
          class="d-flex justify-content-end"
          cols="8"
        >
          <b-form-input
            v-model="filter"
            type="search"
            size="sm"
            placeholder="Pesquisar"
          />
        </b-col>
      </b-row>
      <b-table
        bordered
        responsive
        select-mode="single"
        :per-page="perPage"
        :current-page="currentPage"
        :fields="columns"
        :filter="filter"
        :items="operacoesNaoImportadas"
        @filtered="onFiltered"
      >
        <template #cell(created_at)="data">
          {{ dataHora(data.item.created_at, false) }}
        </template>

        <template #cell(data_vencimento)="data">
          {{ formatTimezone(data.item.data_vencimento) }}
        </template>
        <template #cell(updated_at)="data">
          {{ dataHora(data.item.updated_at, false) }}
        </template>

      </b-table>
      <b-pagination
        v-model="currentPage"
        :per-page="perPage"
        :total-rows="operacoesNaoImportadas.length"
        align="center"
        size="sm"
      />

    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BFormSelect, BCol, BFormInput, BTable, BPagination,
} from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BPagination,
    BCol,
    BRow,
    BFormSelect,
    BTable,
    BFormInput,
  },

  props: {
    operacoesNaoImportadas: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      filter: '',
      totalRows: 1,
      perPage: 10,
      currentPage: 1,
      columns: [
        { key: 'remessa_id', label: 'id remessa', class: 'text-center' },
        { key: 'cpf_cnpj', label: 'cpf/cnpj', class: 'text-center' },
        { key: 'created_at', label: 'data importação', class: 'text-center' },
        { key: 'data_vencimento', label: 'vencimento', class: 'text-center' },
        // { key: 'id', label: 'id', class: 'text-center' },
        { key: 'nome', label: 'nome' },
        { key: 'numero_operacao', label: 'numero operação', class: 'text-center' },
        { key: 'updated_at', label: 'atualização', class: 'text-center' },
        { key: 'valor_nominal', label: 'valor nominal', class: 'text-center' },
      ],
    }
  },

  methods: {
    created() {
      this.totalRows = this.operacoesNaoImportadas.length
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },

  },

}
</script>

<style>

</style>
